import React from 'react'
import SeoPage from '../components/SeoPage'

import Img from '../components/Image'
import { graphql, Link } from 'gatsby'

export default ({ data, location, ...props }) => (
    <SeoPage
        location={location}
        title="TOUS les moyens de défense contre une accusation d’alcool au volant!"
        description="“ Invoquez la MEILLEURE défense contre votre accusation d’alcool au volant – Consultez un avocat criminaliste spécialisé!">
        <p>
            <strong>
                On dit de l’alcool au volant qu’il s’agit d’une infraction
                contre laquelle il est difficile de se défendre. Mais est-ce
                vraiment le cas?{' '}
            </strong>
        </p>
        <p>
            Tout dépend des circonstances, mais force est d’admettre qu’une
            infraction de conduite avec les facultés affaiblies dispose de peu
            de moyens de défense pour exonérer le conducteur.{' '}
        </p>
        <p>
            Et c’est pourquoi les services d’un avocat criminaliste spécialisé
            sont essentiels pour soulever une défense solide contre une telle
            accusation!{' '}
        </p>
        <p>
            <Img
                fluid={data.img0.childImageSharp.fluid}
                title="Quelles défenses pour l’alcool au volant?"
                alt="Une image contenant personne, voiture, téléphone mobile, fermer"
            />
        </p>
        <p>
            <strong>
                Avocatsalcoolauvolant.ca vous présente TOUS les moyens de
                défense qui pourraient être soulevés afin de vous éviter une
                condamnation.{' '}
            </strong>
        </p>
        <p>
            Consultez ces moyens de défense juste ici et remplissez notre
            formulaire pour entrer en contact rapidement, et gratuitement avec
            un avocat qualifié en matière d’alcool au volant. Votre défense
            dépend de ce professionnel!{' '}
        </p>
        <h2>
            Quelles sont les conséquences si on vous trouve coupable d’alcool au
            volant?{' '}
        </h2>
        <p>
            <strong>
                À peu près tout le monde sait que l’alcool au volant, ça ne
                passe pas.{' '}
            </strong>
        </p>
        <p>
            Et la loi est venue affirmer ce principe haut et fort en prévoyant
            des conséquences sévères telles que la suspension immédiate du
            permis de conduire, la saisie du véhicule (selon le cas),
            l’imposition d’un casier judiciaire, une amende minimale de 1000$ et
            même l’emprisonnement.{' '}
        </p>
        <p>
            <strong>
                La récidive ne fait qu’aggraver les conséquences en matière
                d’alcool au volant. EN voici la preuve!{' '}
            </strong>
        </p>
        <h3>Pour une première infraction d’alcool au volant </h3>
        <p>
            Une 1<sup>ère</sup> infraction reliée à l’alcool au volant vient
            avec une suspension administrative du permis de conduire pour 90
            jours, une amende minimale de 1000$, une interdiction de conduire de
            1 à 3 ans et avec un casier judiciaire.{' '}
        </p>
        <p>
            Un antidémarreur devra également être installé pour conserver le
            droit de conduire pendant l’interdiction. Le taux d’alcoolémie au
            moment de l’infraction peut faire grimper le montant de l’amende
            jusqu’à 2000$.{' '}
        </p>
        <h3>Pour une seconde infraction d’alcool au volant </h3>
        <p>
            Une période minimale d’emprisonnement de 30 jours est imposée lors
            d’une première récidive. Une interdiction de conduire absolue de 3
            mois sera imposée au conducteur, et une interdiction de 2 à 10 ans
            pourra être ordonnée. Dans le cas d’une troisième infraction
            d’alcool au volant, la période minimale d’emprisonnement passe à 120
            jours.{' '}
        </p>
        <p>
            <strong>
                Que vous en soyez à votre première ou à votre énième infraction
                d’alcool au volant, une chose demeure inchangée : vous avez
                toujours besoin d’un avocat pour vous représenter et pour vous
                défendre!
            </strong>
        </p>
        <h2>
            Alcool au volant : pourquoi avez-vous besoin d’un avocat
            criminaliste pour défendre?{' '}
        </h2>
        <p>
            <strong>
                Engager un avocat en droit criminel, c’est le meilleur moyen de
                se défendre contre une accusation de conduite avec les facultés
                affaiblies.{' '}
            </strong>
        </p>
        <p>
            <strong>
                <Img
                    fluid={data.img1.childImageSharp.fluid}
                    title="Avocat criminaliste accusation d’alcool au volant"
                    alt="Une image contenant intérieur, conteneur, verre"
                />
            </strong>
        </p>
        <p>
            L’expertise et les connaissances de l’avocat criminaliste sont sans
            égal en matière d’alcool au volant, et voici pourquoi vous avez
            intérêt à en engager un :<strong> </strong>
        </p>
        <h3>Un avocat qualifié sait comment choisir la bonne défense </h3>
        <p>
            Les défenses sont limitées en matière d’alcool au volant, et les
            moindres détails comptent pour obtenir un verdict de
            non-culpabilité. L’avocat criminaliste connait suffisamment le
            système pour choisir la défense qui vous convient.{' '}
        </p>
        <h3>L’avocat en droit criminel peut négocier avec le procureur </h3>
        <p>
            Une accusation pour conduite avec les facultés affaiblies, ça se
            négocie! Il suffit que le procureur accepte de négocier, et que
            votre avocat le convainque d’abandonner le chef d’accusation.{' '}
        </p>
        <h3>Seul un avocat criminaliste peut vous défendre en cour </h3>
        <p>
            Devant le tribunal, seul un avocat est apte et autorisé à vous
            représenter devant le juge. Ses talents de plaideur sont mis à votre
            profit, ce qui maximise vos chances d’être déclaré non-coupable!{' '}
        </p>
        <h2>
            Quels sont les moyens de défense possibles en matière d’alcool au
            volant?
        </h2>
        <p>
            Dans les dernières années, de nouvelles lois sont venues limiter les
            défenses possibles en matière d’alcool au volant afin de resserrer
            la vis aux conducteurs en état d’ébriété. Cela ne signifie toutefois
            pas que vous êtes condamné d’avance.
        </p>
        <p>
            <strong>
                Voici quelques-unes des défenses qui pourraient vous sortir
                d’impasse!{' '}
            </strong>
        </p>
        <h3>Preuve de conduite ou de garde et contrôle insuffisante</h3>
        <p>
            <strong>
                L’alcool au volant, c’est une accusation criminelle. La preuve
                doit donc être démontrée hors de tout doute raisonnable!{' '}
            </strong>
        </p>
        <p>
            Il est possible d’attaquer la preuve récoltée par les policiers et
            par le procureur en plaidant qu’elle ne permet pas de conclure hors
            de tout doute raisonnable que l’accusé a conduit son véhicule en
            état d’ébriété. La preuve quant à l’identification du conducteur
            peut notamment être contestée.
        </p>
        <p>
            <strong>
                Il est également possible d’attaquer l’accusation de garde et
                contrôle.{' '}
            </strong>
        </p>
        <p>
            Cette accusation vise les individus accusés d’avoir assumé la garde
            et le contrôle d’un véhicule alors que leur taux d’alcoolémie
            dépassait la limite permise. Le fait de prouver qu’il n’existait
            aucun risque de mettre le véhicule en marche ou que le conducteur
            avait un plan alternatif pourrait lui permettre de se sortir
            d’impasse.{' '}
        </p>
        <p>
            C’est au procureur que revient le fardeau de prouver la conduite
            illicite hors de tout doute raisonnable, alors les défenses
            consistent généralement à contester la suffisance d’une telle
            preuve.{' '}
        </p>
        <h3>Utilisation inadéquate de l’alcootest (ADA)</h3>
        <p>
            <strong>
                Il est possible de reprocher aux policiers d’avoir négligé les
                règles de l’art quant à la manipulation de l’alcootest.{' '}
            </strong>
        </p>
        <p>
            L’utilisation d’un alcootest non conforme peut donc servir de moyen
            de défense, car le résultat de ce même test est la preuve la plus
            incriminante contre l’accusé.<strong> </strong>
        </p>
        <p>
            <strong>
                <Img
                    fluid={data.img2.childImageSharp.fluid}
                    title="Défense d’utilisation inadéquate de l’alcootest"
                    alt="Une image contenant personne, voiture, véhicule, fauteuil"
                />
            </strong>
        </p>
        <p>
            La loi encadre également le type d’alcootest que les policiers
            peuvent faire passer aux conducteurs, et il doit s’agir d’un
            appareil de détection approuvé (ADA) pour que le résultat soit
            utilisable en preuve.{' '}
        </p>
        <p>
            <strong>
                Voici les principaux moyens de se défendre en contestant
                l’exactitude d’un alcootest :
            </strong>
        </p>
        <ul>
            <li>Utilisation déficiente de l’alcootest </li>
            <li>Entretien inadéquat de l’appareil (ADA) </li>
            <li>Délais indus dans la passation du test </li>
            <li>Appareil de détection non approuvé </li>
        </ul>
        <h3>Délai indu dans la prise d’échantillon d’haleine </h3>
        <p>
            <strong>
                La prise d’un échantillon d’haleine doit être effectuée sans
                délai lors de l’arrestation d’un conducteur.{' '}
            </strong>
        </p>
        <p>
            Lorsque les policiers ne soumettent pas le conducteur à un test de
            détection d’alcool avec immédiateté, le conducteur peut faire valoir
            une défense basée sur les délais indus reliés au test de dépistage.{' '}
        </p>
        <p>
            Lors de décisions récentes, des juges ont accepté d’écarter le
            résultat de l’alcootest effectué par les policiers, car ces derniers
            avaient attendu près de 20 minutes avant de le faire passer au
            conducteur.{' '}
        </p>
        <p>
            <strong>
                Le test doit se faire immédiatement, sans quoi les critères
                prévus par la loi ne seront pas respectés.{' '}
            </strong>
        </p>
        <h3>Non-respect des droits fondamentaux du conducteur</h3>
        <p>
            Les défenses en vertu de la Charte canadienne font partie des plus
            efficaces en matière d’alcool au volant.{' '}
            <strong>
                Lorsque les droits fondamentaux du conducteur sont bafoués, il
                est possible d’exclure certains éléments de preuve!
            </strong>{' '}
        </p>
        <h4>Non-respect du droit à un avocat </h4>
        <p>
            Dès le moment de son arrestation, le conducteur doit être informé
            sans délai de son droit de consulter un avocat. Un manquement
            d’informer le conducteur de ce droit peut justifier le retrait des
            accusations.{' '}
        </p>
        <h4>Fouille abusive et arrestation arbitraire </h4>
        <p>
            Les policiers ont effectué une fouille du véhicule sans mandat ou de
            façon abusive? L’interception du véhicule s’est faite sans raison et
            de façon illégale? Si les droits fondamentaux de l’accusé ont été
            violés lors de telles procédures, un avocat pourra plaider en faveur
            du retrait des accusations.{' '}
        </p>
        <p>
            <strong>ATTENTION! </strong>Depuis déjà quelques années, les
            policiers n’ont plus besoin d’avoir des soupçons ou des « motifs
            raisonnables » de croire qu’un conducteur est intoxiqué pour lui
            faire passer un alcootest.<strong> </strong>Tout policier qui a un
            appareil approuvé en sa possession peut contraindre un conducteur à
            s’y soumettre.<strong> </strong>
        </p>
        <h3>Défense du dernier verre ou du verre d’après </h3>
        <p>
            <strong>
                De telles défenses sont-elles toujours valides en matière
                d’alcool au volant?{' '}
            </strong>
        </p>
        <p>
            Malheureusement, le glas a sonné pour les défenses du verre d’après
            et celle du dernier verre. Les nouvelles lois sont venues anéantir
            de telles défenses, choses que les tribunaux ont reconnus.{' '}
        </p>
        <p>
            La défense du verre d’après consistait à plaider que l’accusé avait
            consommé de l’alcool après avoir conduit, mais avant de se soumettre
            au test, car il n’avait aucune raison de croire qu’un tel test lui
            serait exigé.{' '}
        </p>
        <p>
            Avec la défense du dernier verre, l’accusé prétendait avoir consommé
            son dernier verre d’alcool tout juste avant de prendre le volant.
            Comme il venait tout juste de boire l’alcool, les effets ne se
            faisaient donc pas ressentir sur ses facultés, même si l’alcootest
            avait été échoué.
        </p>
        <h2>
            Absolution ou retrait d’accusations : est-ce possible en matière
            d’alcool au volant?
        </h2>
        <p>
            La meilleure façon d’éviter d’être condamné pour alcool au volant,
            c’est d’éviter d’être accusé, tout simplement. D’où l’importance
            d’être représenté par un avocat solide, et spécialisé en matière
            criminelle.{' '}
        </p>
        <p>
            <Img
                fluid={data.img3.childImageSharp.fluid}
                title="Retrait des accusations d’alcool au volant"
                alt="Une image contenant voiture, personne, fauteuil, siège de voiture"
            />
        </p>
        <p>
            <strong>
                Mais l’absolution est-elle possible en cas de conduite avec les
                facultés affaiblies?
            </strong>
        </p>
        <p>
            L’absolution est une peine prévue au Code criminel qui évite à
            l’accusé d’hériter d’un casier judiciaire. Elle peut être
            inconditionnelle ou conditionnelle. Dans ce dernier cas, l’accusé
            devra respecter certaines conditions pour profiter des effets de
            l’absolution. Cette sentence clémente ne s’applique que si
            l’infraction n’a pas de peine minimale.
        </p>
        <p>
            Il est donc impossible d’obtenir l’abolition pour une infraction
            d’alcool au volant, car elle est assortie d’une peine minimale!
            C’est pourquoi le retrait des accusations ou la préparation d’une
            défense solide sont aussi importants en matière d’alcool au volant
        </p>
        <h2>
            Plaider coupable peut-il être avantageux en matière d’alcool au
            volant?{' '}
        </h2>
        <p>
            <strong>
                Les plaidoyers de culpabilité sont fréquents en matière d’alcool
                au volant.{' '}
            </strong>
        </p>
        <p>
            Il arrive que la preuve récoltée par la poursuite soit simplement
            trop incriminante… mais à quel moment faut-il considérer plaider
            coupable pour une telle?<strong> </strong>
        </p>
        <p>
            <strong>
                Plaider coupable peut venir avec des avantages au niveau de la
                sentence!{' '}
            </strong>
        </p>
        <p>
            La peine minimale prévue au Code criminel sera imposée au conducteur
            même s’il plaide coupable. Toutefois, un juge pourrait autoriser un
            conducteur à conserver son droit de conduire malgré la période
            d’interdiction, à condition qu’il ait un motif suffisant.{' '}
        </p>
        <p>
            <strong>
                Chose certaine, il ne faut surtout pas plaider coupable avant
                d’avoir exploré ses options avec un avocat criminaliste
                spécialisé en accusations d’alcool au volant.{' '}
            </strong>
        </p>
        <h2>
            Consultez un avocat pour invoquer le bon moyen de défense contre
            votre accusation!
        </h2>
        <p>
            <strong>
                Un verdict de culpabilité en matière d’alcool au volant peut
                venir avec de lourdes conséquences.
            </strong>
        </p>
        <p>
            <strong>
                <Img
                    fluid={data.img4.childImageSharp.fluid}
                    title="Meilleure défense contre l’alcool au volant"
                    alt="Une image contenant texte, table, personne, guichet"
                />
            </strong>
        </p>
        <p>
            Du casier judiciaire jusqu’au permis révoqué en passant par les
            amendes salées, sachez qu’un tel verdict peut grandement affecter
            votre vie.{' '}
        </p>
        <p>
            Il est donc très important de vous entourer des bons professionnels
            comme l’avocat en droit criminel pour vous défendre contre une telle
            accusation.{' '}
        </p>
        <p>
            <strong>
                Et ça tombe bien, car on vous propose justement de vous référer
                à l’un d’entre eux gratuitement, et sans engagement. Il ne reste
                qu’à remplir notre formulaire, et on s’occupe du reste!{' '}
            </strong>
        </p>
    </SeoPage>
)

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        img0: file(
            relativePath: {
                eq: "defenses-alcool-volant/moyens-defense-alcool-volant.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img1: file(
            relativePath: {
                eq: "defenses-alcool-volant/avocat-criminaliste-alcool-volant.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img2: file(
            relativePath: {
                eq: "defenses-alcool-volant/mauvaise-utilisation-alcootest-policiers.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img3: file(
            relativePath: {
                eq: "defenses-alcool-volant/retrait-accusations-alcool-volant.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img4: file(
            relativePath: {
                eq: "defenses-alcool-volant/meilleure-defense-facultes-affaiblies.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
